import React from 'react';
import { Layout, Carousel, Typography, Space } from 'antd';
const { Content, Footer } = Layout;
import '../styles/start.scss';
import iconHero from '../images/Hero.svg';

const StartPage = () => {

  return (
    <Layout className="content-start">
      <Content>
        <Carousel autoplay dotPosition="bottom">
          <Space direction="vertical">
            <img className="img" src={iconHero} alt="Hero.svg"/>
            <Typography.Text type="secondary" level={3} className="content-style">
              Conglo cuenta con un sistema automático de alertar.
            </Typography.Text>
          </Space>

          <Space direction="vertical">
            <img className="img" src={iconHero} alt="Hero.svg"/>
            <Typography.Text type="secondary" level={3} className="content-style">
              Comparte la ubicación de tu viaje con otros amigos o familia
            </Typography.Text>
          </Space>

          <Space direction="vertical">
            <img className="img" src={iconHero} alt="Hero.svg"/>
            <Typography.Text type="secondary" level={3} className="content-style">
              Sistema de recompensas, por buenos hábitos
            </Typography.Text>
          </Space>
        </Carousel>
      </Content>
      <Footer className="footer-intro" justify="center" aling="center">
        <Typography.Link strong href="/">Saltar</Typography.Link>
      </Footer>
    </Layout>
  );
};

export default StartPage;
